<template>
	<!-- 
		This is a complete copy of `primary-top-nav.vue`, adapted for the new [Aug 2023] sidebar based navigation.
		Once implemented, `primary-top-nav.vue` and /layouts/PrimaryLayout.vue` need to be name obsolete.
	-->



	<div class="sidenav-menu">	
		<nav-item v-for="item in getItems" :key="item.id" v-bind:item="item" @expandOrCollapseGroup="onExpandOrCollapseGroup"></nav-item>
	</div>
</template>


<script>
import globalStore from "/globalStore";
import navItem from "./primary-side-nav-item.vue";


export default {

	props: {
		// What sort of page/layout context this is running in.
		// Options: 'standard', 'mobile-app'
        context: {
			type: String,
			required: false,
            default: "standard"
		},
	},



	components: {
		'nav-item': navItem,
	},





	
	data()
	{
		return {
			globalStore: globalStore,


			itemCount: 0,
			items: [],

			isLoadingMenu: true,

			activeItem: "",

			currentUserInitials: "",

			isSystemAdmin: false,

			selectedModule: "",
			selectedModuleStorageKey: "PrimaryTopNav:SelectedModile",

			helpUrl: "/kb",


			modules: {
				global: { Name: "Global" },
				timesheets: { Name: "Timesheets" },
				quoting: { Name: "Quoting" },
				tessta: { Name: "Tessta" },
				tesstaClients: { Name: "Tessta Client Portal" },
				rostering: { Name: "Rostering" },
				lotus: { Name: "Lotus" },
			},



			//-- New side menu
			// isCollapsed: false
		};
	},





	computed: {
		getItems()
		{
			
			// Wait until loading of menu items is complete. Null stripping needs to happen.
			if (this.isLoadingMenu == true) return [];


			//console.log("getItems(): ", this.items);
			return this.items;
		},


		selectedModuleName()
		{
			var name = this.modules[this.selectedModule]?.Name;

			if (!name) return "";

			return name;
		}
	},




	mounted()
	{
		this.init();
	},





	methods: {
		init()
		{
			//console.log("primary-side-nav: (" + this.$route.name + ") this.$route=", this.$route);


			// System Admin check
			this.isSystemAdmin = this.$root.isSystemAdminUser();

			// Get the last set module from local storage
			this.selectedModule = globalStore.localStorage.getDataFromStorage(this.selectedModuleStorageKey);
			if (!this.selectedModule) this.selectedModule = '';

			this.loadCurrentUserInitials();

			this.initialiseMenu();
		},





		initialiseMenu()
		{
			this.items = [];


			//TODO: Scenario to handle:
			//		We switch modules in the men - that's fine.
			//		Then navigate to a page (full page refresh) which is going to lose the selected module.
			//		We need to remember the selected module through refreshes (e.g localStorage)
			//		Always assume a page refresh can happen (even if we eventually get most pages into true SPA routes without refresh).


			if (this.context == 'standard')
			{
				this.loadItemsForStandard();
			}
			if (this.context == 'mobile-app')
			{
				this.loadItemsForMobileApp();
			}


			// Find any child arrays with a null value in an index, and remove.
			this.stripNullChildren();


			// Only after null stripping.
			this.isLoadingMenu = false;



			this.setActiveMenuItem();
		},




		setActiveMenuItem()
		{
			const _this = this;
			const currentRouteName = this.$root.getCurrentRouteName();

			//console.log("currentRouteName=", currentRouteName);

			function checkItemsArray(array, debugLevel)
			{
				let containsActiveItem = false;

				// Nothing to do
				if (!array || array.length == 0) return;

				// Find and strip nulls item in the array.
				for (let i = 0; i < array.length; i++)
				{
					var item = array[i];
					var associatedRouteNames = item.associatedRouteNames;

					if (associatedRouteNames && associatedRouteNames.includes(currentRouteName))
					{
						item.isActive = true;
						containsActiveItem = true;
						_this.activeItem = item.id;
					}
					else
					{
						// Ensure all other items are disabled as active.
						item.isActive = false;
					}
				}

				// Go down the tree of children. Do it in a new loop after any nulls are stripped from the array.
				for (let i = 0; i < array.length; i++)
				{
					const children = array[i].children;
				
					if (children && children.length > 0)
					{
						//console.log(spaces(debugLevel * 2) + " - call children...", children);
						const childrenContainsActiveItem = checkItemsArray(children, debugLevel + 1);
						if (childrenContainsActiveItem)
						{
							// One of the children is the active item, so we want to ensure the group it's in is expanded.
							array[i].isActiveGroup = true;
						}
						else
						{
							// Ensure all other groups are not set as having an active item.
							array[i].isActiveGroup = false;
						}
					}
				}

				return containsActiveItem;
			}


			checkItemsArray(this.items, 1);
		},

		



		/**
		 * Check if the route name of the current page is the provided name.
		 */
		isCurrentRouteByName(routeName)
		{
			//var currentRouteName = this.$router.currentRoute.name;
			const currentRouteName = this.$root.getCurrentRouteName();

			return (currentRouteName.toLowerCase() == routeName.toLowerCase());
		},













		/**
		 * Load the menu items for pages embedded within the mobile app.
		 */
		loadItemsForMobileApp()
		{
			//-- Manage: Jobs, Tasks and Scheduling
			if(this.$root.hasAccess('AccountAdmin.All') || this.$root.hasAccess('ManageAdmin.All') || this.$root.hasAccess('GeneralAdministrator.All'))
			{
				this.addItemFaIcon("New Job", "fas fa-plus", "/mobile-app/new-job", []);

				this.addItemFaIcon("Manage Jobs", "fas fa-tasks", "/mobile-app/manage-jobs", []);
			}

		},










		/**
		 * Load the menu items for pages viewed within the standard portal in a browser.
		 */
		loadItemsForStandard()
		{
			var _this = this;




			//----- HOME -----
			//TDOO: Disable until we have an actual page.
			function homeItems()
			{
				// All users can access this page
				_this.addItemFaIcon("Home", "fas fa-home", "/home", []);
			}
			//homeItems();






			
			//---- Account Admin -----
			function accountAdmin()
			{
				if(_this.$root.hasAccess('AccountAdmin.All'))
				{
					const accountId = _this.globalStore.state.currentUser.activeAccountId;

					_this.addModuleRootItemFaIcon('account', "Account", "far fa-user-circle", "/account-admin/" + accountId, 
					null,
					null,
					["account-admin-root", "account-admin-home"]
				);
				}
			}
			accountAdmin();

			




			//---- Timesheets module -----
			function timesheetsModule()
			{
				if (_this.$root.hasAccess('TesstaClients.ClientPortal'))
				{
					// Make sure this is not available in the client portal. For some reason it's still coming up (though I am aliased in)
					return;
				}
				
				//TODO: This is currently a module enabled check. It needs to be a specific flag instead (e.g. Timesheets.General)
				if (!_this.$root.hasAccess('Timesheets.Enabled'))		//TODO: The standard check
				{
					return;
				}





				//NOTE: "(👁)" denotes under development/dev only at the moment.

				var accountId = _this.globalStore.state.currentUser.activeAccountId;

				var children = [];
				children.push(_this.getItem("Home", "far fa-file-alt", "/home/timesheets", [], ["home-timesheets-view", "view-timesheet", "edit-timesheet"]));

				//TODO: Currently under development
				if (_this.$root.hasAccess('LotusDevelopment.All'))
				{
					children.push(_this.getItem("Clients, Buckets & Tasks (👁)", "far fa-file-alt", "/manage/" + accountId + "/clients-buckets-tasks", [], ["manage-clients-buckets-tasks"]));
				}

				children.push(_this.getItem("Clients", "far fa-file-alt", "/manage/" + accountId + "/clients", [], ["manage-clients"]));
				children.push(_this.getItem("Buckets", "far fa-file-alt", "/manage/" + accountId + "/buckets", [], ["manage-buckets"]));

				//TODO: Currently under development
				let bucketsReportItem = null;
				if (_this.$root.hasAccess('LotusDevelopment.All'))
				{
					// Still in development
					bucketsReportItem = _this.getItem("Buckets Report (👁)", "icon-projects", "/module/timesheets/buckets-report", [], 
						["timesheet-buckets-report","timesheet-buckets-report-entry","timesheet-buckets-report-account","timesheet-buckets-report-bucket-summary","timesheet-buckets-report-bucket-detail","timesheet-buckets-report-bucket-breakdown"]
					);
					children.push(bucketsReportItem);
				}

				//TODO: Currently under development
				let newKeywordsItem = null;
				if (_this.$root.hasAccess('LotusDevelopment.All'))
				{
					// Still in development
					newKeywordsItem = _this.getItem("Extract Keywords (👁)", "far fa-file-alt", "/module/timesheets/keywords/new-detected", [], ["timesheets-new-detected-keywords"]);
					children.push(newKeywordsItem);
				}

				//TODO: Update this when ready for production.
				// if (_this.isDevelopment())
				// {
				// 	if (_this.$root.hasAccess('Timesheet.Manager'))
				// 	{
						
				// 		children.push(_this.getItem("Outstanding Timesheets", "far fa-file-alt", "/module/timesheets/manager/outstanding-timesheets", [], ["timesheets-manager-outstanding"]));
				// 		children.push(_this.getItem("Timesheet Report", "far fa-file-alt", "/module/timesheets/manager/timesheets-report", [], ["timesheets-manager-report"]));
				// 	}
				// }


				_this.addModuleRootItemFaIcon('timesheets', 'Timesheets', 'far fa-clock', '', children);
			}
			timesheetsModule();


			


			//---- Quoting module -----
			//
			//TODO: As of around end of 2022 this is incomplete and now effecively deprecated.
			function quotingModule()
			{	
				// Quoting dashboard.
				if (!_this.$root.hasAccess('Quoting.All'))
				{
					return;
				}


				var accountId = _this.globalStore.state.currentUser.activeAccountId;

				_this.addModuleRootItemFaIcon('quoting', 'Quoting', 'far fa-file-alt', '', 
					[
						_this.getItem("Home", "fas fa-home", "/module/quotes", [], ["quotes-root"]),		// Also: /home/quoting (get rid of that route)
						_this.getItem("Clients", "far fa-file-alt","/manage/" + accountId + "/clients", [], ["manage-clients"]),
						_this.getItem("Manage Quotes", "far fa-file-alt", "/module/quotes/manage", [], ["quotes-manage"]),
						_this.getItem("New quote", "far fa-file-alt", "/module/quotes/new/select-client", [], ["quotes-select-client"]),

						// From the old "Manage" sub-menu. Putting them here for now so they're not lost, then we can get rid of them later
						_this.getItem("Manage Quotes 2", "fas fa-th-large", "/manage/quotes", [], ["manage-quotes"]),
						_this.getItem("Manage Jobs", "fas fa-th-large", "/manage/jobs", [], ["manage-jobs"]),
						_this.getItem("Addresses", "fas fa-th-large", "/manage/addresses", [], ["manage-addresses"]),
						_this.getItem("Organisations", "fas fa-th-large", "/manage/organisations", [], ["manage-organisations"]),
						_this.getItem("People", "fas fa-th-large", "/manage/people", [], ["manage-people"]),
					]
				);
			}
			quotingModule();










			//----- Module: CRM -----
			function crmModule()
			{
				//if (!_this.$root.hasAccess('Tessta.Administration') && !_this.$root.hasAccess('TesstaClients.ClientPortal'))
				if (!(_this.isDevelopment() && _this.$root.hasAccess('LotusDevelopment.All')))
				{
					return;
				}

				_this.addModuleRootItemFaIcon('crm', "CRM (👁)", "fas fa-people-arrows", "", 
					[
						_this.getItem("Configure", "fas fa-home", "/crm/configure", [], ["crm-index","crm-configure"]),

						// _this.getItem("Home", "fas fa-home", "/module/tessta-clients", [], ["tessta-clients-index","tessta-clients-home-index"]),
						// _this.getItem("Defects", "fas fa-bug", "/module/tessta-clients/defects", [], ["tessta-clients-defects-index","tessta-clients-defects-edit"]),
						// //_this.getItem("Sites", "fas fa-building", "/module/tessta-clients/sites", [], ["tessta-clients-sites-index","tessta-clients-sites-edit"]),
						// // _this.getItem("Assessments", "fas fa-tasks", "/module/tessta-clients/assessments", [], ["tessta-clients-assessments-index"]),	//TODO: Hidden while not implemented
					],
				);
			}
			crmModule();

			



			//----- Module: Tessta -----
			function tesstaModule()
			{
				if (!_this.$root.hasAccess('Tessta.Administration'))
				{
					return;
				}

				_this.addModuleRootItemFaIcon('tessta', "Tessta Admin", "fas fa-road", "", 
					[
						_this.getItem("Home", "fas fa-home", "/module/tessta", [], ["tessta-admin-root", "tessta-admin-home-index"]),
						_this.getItem("Clients", "far fa-address-book", "/module/tessta/clients", [], ["tessta-admin-clients-index","tessta-admin-clients-create","tessta-admin-clients-edit"]),
						_this.getItem("Sites", "fas fa-building", "/module/tessta/sites", [], ["tessta-admin-sites-index","tessta-admin-sites-create","tessta-admin-sites-edit","tessta-admin-sites-assessment"]),
						_this.getItem("Projects", "fas fa-tasks", "/module/tessta/projects", [], ["tessta-admin-projects-index","tessta-admin-projects-create","tessta-admin-projects-edit"]),
						_this.getItem("QA", "fas fa-check-double", "/module/tessta/qa", [], ["tessta-admin-qa-index"]),
						_this.getItem("Defects", "fas fa-bug", "/module/tessta/defects", [], ["tessta-admin-defects-index","tessta-admin-defects-edit"]),
						_this.getItem("Config", "fas fa-cogs", "/module/tessta/configuration", [], 
							["tessta-admin-configuration-index","tessta-admin-configuration-users-index","tessta-admin-configuration-users-edit",
							"tessta-admin-configuration-assessments-index","tessta-admin-configuration-assessment-create","tessta-admin-configuration-assessment-edit",
							"tessta-admin-configuration-assets-index","tessta-admin-configuration-asset-edit",
							"tessta-admin-configuration-reference-data-index","tessta-admin-configuration-reference-data-edit",
							"tessta-admin-configuration-work-orders-index","tessta-admin-configuration-work-orders-edit",
							"tessta-admin-configuration-room-elements-index","tessta-admin-configuration-room-elements-edit",
						]
						),
					],
				);
			}
			tesstaModule();





			//----- Module: Tessta Clients -----
			function tesstaClientsModule()
			{
				if (!_this.$root.hasAccess('Tessta.Administration') && !_this.$root.hasAccess('TesstaClients.ClientPortal'))
				{
					return;
				}

				_this.addModuleRootItemFaIcon('tesstaClients', "Tessta Client Portal", "fas fa-people-arrows", "", 
					[
						_this.getItem("Home", "fas fa-home", "/module/tessta-clients", [], ["tessta-clients-index","tessta-clients-home-index"]),
						_this.getItem("Defects", "fas fa-bug", "/module/tessta-clients/defects", [], ["tessta-clients-defects-index","tessta-clients-defects-edit"]),
						//_this.getItem("Sites", "fas fa-building", "/module/tessta-clients/sites", [], ["tessta-clients-sites-index","tessta-clients-sites-edit"]),
						// _this.getItem("Assessments", "fas fa-tasks", "/module/tessta-clients/assessments", [], ["tessta-clients-assessments-index"]),	//TODO: Hidden while not implemented
					],
				);
			}
			tesstaClientsModule();

			



			//----- Module: Rostering -----
			function rosteringModule()
			{
				if (!_this.$root.hasAccess('Rostering.RosteringAdministrator'))
				{
					return;
				}

				_this.addModuleRootItemFaIcon('rostering', 'Rostering', 'far fa-file-alt', '', 
					[
						_this.getItem("Jobs Admin", "fas fa-tasks", "/module/rostering/admin", [], ["rostering-admin-page", "rostering-admin-new-job","rostering-admin-edit-job"]),
						_this.getItem("Payroll Report", "fas fa-table", "/module/rostering/admin/reports/payroll", [], ["rostering-admin-reports-payroll"]),
						_this.getItem("Kiosk", "fas fa-sign-in-alt", "/module/rostering/kiosk", [], ["rostering-kiosk-index"]),
					],
				);
			}
			rosteringModule();

			




			//----- Road management (PoC) [OBSOLETE] -----
			function roadManagementProofOfConcept()
			{
				//TODO: PoC is complete. Keep for a period until we know we don't need it again.
				const isDisabled = true;
				if (isDisabled) return;


				if (!_this.$root.hasAccess('Pavements.All'))
				{
					return;
				}

				_this.addItemFaIcon("Pavements (PoC)", "fas fa-road", "/module/pavements-poc", [
					{
						id: "pavements-import",
						text: 'Import',
						icon: '',
						faIcon: 'fas fa-file-import',
						url: '/module/pavements-poc/import',
						children: [],
					},
					{
						id: "pavements-defects",
						text: 'Defects',
						icon: '',
						faIcon: 'fa-solid fa-house-crack',
						url: '/module/pavements-poc/defects',
						children: [],
					},
				]);
			}
			roadManagementProofOfConcept();
			
			



			//----- Lotus Admin / System Admin -----
			function lotusSystemAdmin()
			{
				if( !(_this.$root.hasAccess('LotusAdministration.All') || _this.$root.hasAccess('LotusDevelopment.All')) )
				{
					return;
				}


				let homeItem = null;
				if (_this.$root.isSystemAdminUser())
				{
					homeItem = _this.getItem("Home", "fas fa-home", "/home/LotusOnly", [], ["home-lotus-only-view"]);
				}

				_this.addModuleRootItemFaIcon('lotus', "Lotus Staff", "fas fa-toolbox", "", 
					[
						homeItem,
						_this.getItem("Accounts", "fas fa-user", "/system-admin/accounts", [], 
							["system-admin-accounts", 
							"system-admin-accounts-edit",
							"account-admin-settings", 
							"account-admin-users",
							"account-admin-user-edit",
							"account-admin-user-access",
							"account-admin-user-new"]
						),


						_this.getItem("Review Timesheets", "far fa-clock", "/system-admin/review-timesheets", [], ["SystemAdminReviewTimesheets"]),
						_this.getItem("Reports", "far fa-file-alt", "/system-admin/reports", [], ["system-admin-reports"]),
					],
				);
			}
			lotusSystemAdmin();



			




			//TODO: [@jason, 9 Aug 2023] Setting this up quickly as an easy way for Narayan and team to access this page 
			//		during development of the Tessta Client Portal.
			function developerGuideInDevAndTest()
			{
				if (!(_this.isDevelopment() && _this.$root.hasAccess('LotusDevelopment.All')))
				{
					// Only run this is dev and test environments.
					return;
				}

				_this.addModuleRootItemFaIcon('developers', "Lotus Developers (👁)", "fas fa-toolbox", "", 
					[
						_this.getItem("Developer Guide", "fas fa-border-style", "/developers/dev-guide", [], 
							["dev-guide-home", "dev-guide-layout", "dev-guide-typography", "dev-guide-buttons", "dev-guide-forms",
							"dev-guide-graphics", "dev-guide-tables",
							"dev-guide-other", "dev-guide-element-ui",
							"dev-guide-test-calendar", "dev-guide-test-calendar-with-timesheet",
							"dev-guide-test-keyword-extraction", "dev-guide-buttons-that-are-obsolete"]
						),
						_this.getItemFaIcon("Background tasks", "fas fa-key", "/background-tasks", []),	// Prototype until ready for all users (maybe)
					],
				);
			}
			developerGuideInDevAndTest();







			



			//-- Temporary Help (should be with the profile icon)
			// _this.addItemCustomIcon("Help", "", "/kb/route/" + _this.$route.name + "?path=" + _this.$route.matched[0].path, []);



			_this.helpUrl = "/kb/with-path/?path=" + _this.$route.matched[0].path;
		},










		loadCurrentUserInitials()
		{
			// console.log("globalStore.state.currentUser=", this.globalStore.state.currentUser);
			var first = "";
			if (this.globalStore.state.currentUser.firstName.length > 0)
			{
				first = this.globalStore.state.currentUser.firstName.substring(0, 1);
			}
			
			var last = "";
			if (this.globalStore.state.currentUser.lastName.length > 0)
			{
				last = this.globalStore.state.currentUser.lastName.substring(0, 1);
			}

			this.currentUserInitials = first + "" + last;
		},







		


		/**
		 * Add an item with a standard (internal custom image) icon.
		 * 
		 * @param {string} text 
		 * @param {string} icon 
		 * @param {string} url 
		 * @param {array} children 
		 * @param {function} func A function to run rather than navigating to the URL.
		 */
		addItemCustomIcon(text, icon, url, children, func)
		{
			const item = this.getItemCustomIcon(text, icon, url, children, func);

			this.items.push(item);
		},


		addModuleRootItemCustomIcon(module, text, icon, url, children, func)
		{
			const item = this.getItemCustomIcon(text, icon, url, children, func);

			item.module = module;

			this.items.push(item);
		},


		getItemCustomIcon(text, icon, url, children, func)
		{
			this.itemCount++;

			const item = {
				id: this.itemCount,
				text: text,
				icon: icon,
				faIcon: "",
				url: url,
				children: children,
				func: func
			};

			return item;
		},










		/**
		 * Add an item with a Font Awesome icon.
		 * param func: A function to run rather than navigating to the URL.
		 */
		addItemFaIcon(text, faIcon, url, children, func)
		{
			const item = this.getItemFaIcon(text, faIcon, url, children, func);

			this.items.push(item);

			return item;
		},


		addModuleRootItemFaIcon(module, text, faIcon, url, children, func, associatedRouteNames)
		{
			const item = this.getItemFaIcon(text, faIcon, url, children, func, associatedRouteNames ? associatedRouteNames : []);

			item.module = module;

			this.items.push(item);

			return item;
		},


		getItemFaIcon(text, faIcon, url, children, func, associatedRouteNames)
		{
			this.itemCount++;

			const item = {
				id: this.itemCount,
				text: text,
				icon: "",
				faIcon: faIcon,
				url: url,
				children: children,
				func: func,
				associatedRouteNames: associatedRouteNames
			};

			return item;
		},



		getItem(text, faIcon, url, children, associatedRouteNames)
		{
			this.itemCount++;

			const item = {
				id: this.itemCount,
				text: text,
				icon: "",
				faIcon: faIcon,
				url: url,
				children: children,
				
				//func: func

				associatedRouteNames: associatedRouteNames ? associatedRouteNames : []
			};

			return item;
		},








		
		/**
		 * Find any child arrays in `items` with a null value in an index, and remove.
		 */
		stripNullChildren()
		{
			function checkAndStripArray(array, debugLevel)
			{
				// Nothing to do
				if (!array || array.length == 0) return;

				// Find and stripp nulls item in the array.
				for (let i = 0; i < array.length; i++)
				{
					//console.log(spaces(debugLevel * 2) + "stripping...", array[i]);
					if (array[i] == null)
					{
						array.splice(i, 1);
					}
				}

				// Go down the tree of children. Do it in a new loop after any nulls are stripped from the array.
				for (let i = 0; i < array.length; i++)
				{
					const children = array[i].children;
				
					if (children && children.length > 0)
					{
						//console.log(spaces(debugLevel * 2) + " - call childre...", children);
						checkAndStripArray(children, debugLevel + 1);
					}
				}
			}


			checkAndStripArray(this.items, 1);
		},










		// isProduction()
		// {
		// 	return config.IsProduction();
		// },



		isDevelopment()
		{
			return this.$root.isDevelopment();
		},



		isTest()
		{
			return this.$root.isTest();
		},

























		/**
		 * Toggle state data for expanding or collapsing a top level menu group.
		 */
		onExpandOrCollapseGroup(data)
		{
			data.item.groupExpandedState = data.toState;
		}

	}
}
</script>








<style>
	#primary-side-navigation
	{
		border-right: none;
	}


	/* Custom style the scrollbar */
	/* width */
	.sidebar-content::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	/* Track */
	.sidebar-content::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 3px; /* Border radius of the thumb */
	}

	/* Handle */
	.sidebar-content::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 3px; /* Border radius of the thumb */
	}

	/* Handle on hover */
	.sidebar-content::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
</style>





<style>
	
	.nav-header{
		/* border-bottom:1px solid rgba(0,0,0,0.065); */
	}
	.nav-item{
		width:auto;
		display: inline-block;
		float: left;
		cursor: pointer;
		text-align: center;
	}

	.nav-item .is-label
	{
		cursor: default;
	}


	.nav-item .nav-item-text,
	.nav-item .nav-item-text a
	{
		font-weight: 500;
		font-size: 13px;
		color:#576874;
		transition: all ease 0.5s;
	}
	.nav-item:hover .nav-item-text,
	.nav-item:hover .nav-item-text a
	{
		color:#FDB515;
	}
	.nav-item .nav-item-icon {
		display:inline-block;
	}
	.nav-item-padding{
		/* padding:33px 23px 23px 23px; */
		padding:13px 23px 13px 23px;
	}
	.nav-item:hover ul{
		display: block;
	}
	.nav-item ul{
		display: none;
		position: absolute;
		list-style: none;
		padding: 0px;
		background: white;
		border-top: 1px solid rgba(0,0,0,0.065);
		/* box-shadow: rgba(0,0,0,0.09) 0px 3px 30px; */
		box-shadow: rgba(0,0,0,0.59) 0px 3px 30px;
		z-index: 999;
		/* border-radius: 0px 0px 12px 12px; */
		border-radius: 0px 0px 4px 4px;
	}
	.nav-item ul li{
		border-bottom:1px solid rgba(0,0,0,0.065);
		display: block;
	}
	.nav-item ul li:last-of-type{
		border-bottom:none;
	}


	.nav-item ul li .label-child
	{
		/* Emulate: .nav-item ul li a */
		font-size: 14px;
		padding:13px 22px 13px 20px;
		display: block;
		letter-spacing: -0.5px;
		color:#576874;
	}

	.nav-item ul li a
	{
		font-size: 14px;
		padding:13px 22px 13px 20px;
		display: block;
		letter-spacing: -0.5px;
		color:#576874;
	}
	.nav-item ul li a:hover
	{
		color:#FDB515;
	}
	
	/* Personal Account */
	.nav-item-account
	{
		font-weight: bold;
		color: #FDB515;
		font-size: 15px;
		background: #FFFDF5;
		/* border:1px solid #FEF7EA;
		border-radius: 30px; */
		border:1px solid #fdf2dc;
		border-radius: 6px;
		padding: 10px;

		margin: 31px;
		
		margin-top: 13px;
		margin-bottom: 13px;

		margin-left: 13px;
		margin-right: 13px;
	}

	.nav-item-help
	{
		font-weight: bold;
		/* color: #FDB515; */
		color:#576874;
		font-size: 15px;
		/* background: #FFFDF5; */
		/* border:1px solid #FEF7EA; */
		/* border-radius: 30px; */
		padding: 10px;

		/* margin-top: 31px;
		margin-bottom: 31px; */

		margin-top: 13px;
		margin-bottom: 13px;
	}

	.nav-item-right{
		float: right;
	}
	
	/* Dashboard */
	.icon-dashboard{
		background: url("https://assets.lotusai.co/portal/images/icon-dashboard.svg");
		background-repeat: no-repeat;
		height:25px;
		width:25px;
		transition: all ease 0.5s;
	}
	.nav-item:hover .icon-dashboard{
		background: url("https://assets.lotusai.co/portal/images/icon-dashboard-hover.svg");
		background-repeat: no-repeat;
	}
	.icon-timesheets{
		background: url("https://assets.lotusai.co/portal/images/icon-timesheets.svg");
		background-repeat: no-repeat;
		height:22px;
		width:22px;
		margin-top:1px;
		margin-bottom:2px;
		transition: all ease 0.5s;
	}
	.nav-item:hover .icon-timesheets{
		background: url("https://assets.lotusai.co/portal/images/icon-timesheets-hover.svg");
		background-repeat: no-repeat;
	}
	.icon-projects{
		background: url("https://assets.lotusai.co/portal/images/icon-projects.svg");
		background-repeat: no-repeat;
		height:23px;
		width:23px;
		margin-top:2px;
		transition: all ease 0.5s;
	}
	.nav-item:hover .icon-projects{
		background: url("https://assets.lotusai.co/portal/images/icon-projects-hover.svg");
		background-repeat: no-repeat;
	}
	.icon-reports{
		background: url("https://assets.lotusai.co/portal/images/icon-reports.svg");
		background-repeat: no-repeat;
		height:23px;
		width:30px;
		margin-top:2px;
		transition:all ease 0.5s;
	}
	.nav-item:hover .icon-reports{
		background: url("https://assets.lotusai.co/portal/images/icon-reports-hover.svg");
		background-repeat: no-repeat;
	}
	.icon-approve{
		background: url("https://assets.lotusai.co/portal/images/icon-approve.svg");
		background-repeat: no-repeat;
		height:20px;
		width:20px;
		margin-top:2px;
		margin-bottom:3px;
		transition:all ease 0.5s;
	}
	.nav-item:hover .icon-approve{
		background: url("https://assets.lotusai.co/portal/images/icon-approve-hover.svg");
		background-repeat: no-repeat;
	}
	.icon-teams{
		background: url("https://assets.lotusai.co/portal/images/icon-teams.svg");
		background-repeat: no-repeat;
		height:20px;
		width:30px;
		margin-top:3px;
		margin-bottom:2px;
		transition:all ease 0.5s;
	}
	.nav-item:hover .icon-teams{
		background: url("https://assets.lotusai.co/portal/images/icon-teams-hover.svg");
		background-repeat: no-repeat;
	}
	.mobile-nav{
		display: none;
	}
	.mobile-nav .nav-menu{
		display: block;
		/* padding: 33px 0px 33px 26px; */
		padding: 10px 0px 10px 10px;
		float: left;
		width: auto;
	}
	.mobile-nav .nav-menu-icon{
		width:90px;
		height: 40px;
		display: block;
		text-align: right;
		display: block;
		float: left;
		background: url("https://assets.lotusai.co/portal/images/icon-mobilemenu.svg");
		background-repeat: no-repeat;
		cursor: pointer;
		color: #576874;
		font-size: 14px;
		padding: 8px 0px;
	}
	.mobile-nav .nav-menu-icon.closemenu{
		background: url("https://assets.lotusai.co/portal/images/icon-mobilemenu-close.svg");
		background-repeat: no-repeat;
	}
	.mobile-nav-items{
		width: 100%;
		/* display: none; */
		display: block;
	}
	.mobile-nav-items ul, .mobile-nav-items ul li{
		width: 100%;
		display: block;
		list-style: none;
		padding:0;
		text-align: left;
	}
	.mobile-nav-items ul li .container{
		border-bottom:1px solid rgba(0,0,0,0.065);
		width:100%;
		display: block;
		/* font-size: 14px; */
		/* color: #576874; */
		padding:15px 15px 15px 43px;
	}
	.mobile-nav-items ul li a{
		/* border-bottom:1px solid rgba(0,0,0,0.065); */
		/* width:100%; */
		width: 80%;
		/* display: block; */
		display: inline-block;
		font-size: 14px;
		color: #576874;
		/* padding:15px 15px 15px 43px; */
	}
	.mobile-nav-items ul li a:hover{
		color:#FDB515;
		transition: all ease 0.5s;
	}
	.mobile-nav-items .icon{
		float: left;
		margin-right: 20px;
		width:30px;
	}
	.mobile-nav-items .icon-mobile-expand{
		float: right;
		display: block;
		background: url("https://assets.lotusai.co/portal/images/icon-mobile-expand.svg");
		width: 20px;
		height: 12px;
		background-repeat: no-repeat;
		cursor: pointer;
		margin: 6px 7px 0px 0px;
		transition:all 0.5s ease;
	}
	.mobile-nav-items .icon-mobile-expand.expanded{
		transform: rotate(180deg);
		margin:5px 7px 0px 0px;
	}
	.mobile-nav-items ul li ul{
		float: left;
		position: relative;
		box-shadow: none;
		border:none;
		background: #F7F9FA;
		display: none;
	}
	.mobile-nav-items ul li:hover ul{
		display: none;
	}
	
	.mobile-nav-items ul li ul li{
		border: none;	
	}
	
	.mobile-nav-items ul li ul li a{
		padding:15px 15px 15px 93px;
	}
	
	/* @media (max-width: 991px)  */
	@media (max-width: 996px) 
	{
		.nav-item-padding{
			padding:33px 15px 23px 15px;
		}
	}
	
	/* @media (max-width: 767px)  */
	@media (max-width: 996px) 
	{
		.desktop-nav{
			display: none;
		}
		.mobile-nav{
			display:block;
		}
	}

</style>




<style lang="scss">
	/* Not scoped at the moment so the sub-component can access the styles */
	/* Ref: https://www.w3schools.com/howto/howto_js_dropdown_sidenav.asp */

	/* Fixed sidenav, full height */
	.sidenav-menu {
		//height: 100%;
		//width: 200px;
		//position: fixed;
		//z-index: 1;
		//top: 0;
		//left: 0;
		//background-color: #111;
		//overflow-x: hidden;
		//padding-top: 20px;
	}

	/* Style the sidenav links and the dropdown button */
	.sidenav-menu a, 
	.dropdown-btn
	{
		padding: 10px 8px 10px 16px;
		text-decoration: none;
		//font-size: 20px;
		color: #303133;
		display: block;
		border: none;
		background: none;
		width:100%;
		text-align: left;
		cursor: pointer;
		outline: none;
	}


	/*
	* Ensure more indent in sub-nav items.
	*/
	a.sub-nav-item
	{ 
		padding-left: 40px;;
	}


	.dropdown-btn,
	.single-top-level-item
	{
		font-weight: 500;
	}

	/* On mouse-over */
	.sidenav-menu a:hover, 
	.dropdown-btn:hover,
	.single-top-level-item:hover
	{
		color: #FDB515;
	}

	/* Main content */
	.main 
	{
		margin-left: 200px; /* Same as the width of the sidenav */
		font-size: 20px; /* Increased text to enable scrolling */
		padding: 0px 10px;
	}

	/* Add an active class to the active dropdown button */
	.active 
	{
		background-color: green;
		color: white;
	}

	/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
	.dropdown-container 
	{
		display: none;
		//background-color: #262626;
		padding-left: 8px;

		padding-bottom: 20px;
	}

	/* Optional: Style the caret down icon */
	//.fa-caret-down,
	.collapsed-group,

	//.fa-caret-up,
	.expanded-group
	{
		float: right;
		padding-right: 8px;
		font-size: 1em;
	}
</style>